<template>
  <form class="card" v-if="persona" @submit.prevent="guardar">
    <h3 class="card-title">Mis datos personales</h3>
    <div class="cols2">
      <pd-input
        v-model="persona.nombre"
        id="nombre"
        label="Nombre"
        placeholder="Nombre"
        readonly
        classInput="form-control readonly text-bold"
      ></pd-input>
      <pd-input
        v-model="cuilFormateado"
        id="cuil"
        label="Cuil"
        placeholder="Cuil"
        readonly
        classInput="form-control readonly text-bold"
      ></pd-input>
    </div>
    <div class="cols2 my-1">
      <pd-input
        v-model="persona.email"
        id="Email"
        label="Email"
        placeholder="Email"
        type="email"
        :required="$v.persona.email.$dirty && !$v.persona.email.required"
      ></pd-input>
      <pd-input
        v-model="persona.telefono_fijo"
        type="number"
        id="telefono_fijo"
        label="Telefono fijo (opcional)"
        placeholder="Telefono fijo (opcional)"
        inputmode="numeric"
      ></pd-input>
      
        <div class="form-group">
          <label class="form-label">Celular (sin 0 ni 15)</label>
          <div class="input-group">
            <div class="input-group input-group-prepend">
              <div class="input-group-prepend">
                <span class="input-group-text">0</span>
              </div>
              <input
                id="caracteristica"
                placeholder="Ej:2954"
                type="number"
                class="form-control"
                inputmode="numeric"
                v-model="persona.caracteristica_celular"
              />
            </div>
            <div class="input-group input-group-append"  style="flex:2">
              <div class="input-group-prepend">
                <span class="input-group-text">15</span>
              </div>
              <input
                id="numero_celular"
                placeholder="Ej: 555555"
                type="number"
                class="form-control"
                inputmode="numeric"
                v-model="persona.numero_celular"
              />
            </div>
          </div>
        </div>
      <pd-input
        v-model="persona.domicilio"
        type="text"
        id="domicilio"
        label="Domicilio"
        placeholder="Ingrese su domicilio..."
        :required="
          $v.persona.domicilio.$dirty && !$v.persona.domicilio.required
        "
      ></pd-input>
      <pd-input
        v-model="persona.localidad"
        type="text"
        id="localidad"
        label="Localidad"
        placeholder="Ingrese su localidad..."
        :required="
          $v.persona.localidad.$dirty && !$v.persona.localidad.required
        "
      ></pd-input>
      <pd-input
        v-model="persona.provincia"
        type="text"
        id="provincia"
        label="Provincia"
        placeholder="Ingrese su provincia..."
        :required="
          $v.persona.provincia.$dirty && !$v.persona.provincia.required
        "
      ></pd-input>
      <pd-input
        v-model="persona.codigo_postal"
        type="number"
        id="codigo_postal"
        label="Código postal"
        placeholder="Ingrese su código postal..."
        :required="
          $v.persona.codigo_postal.$dirty && !$v.persona.codigo_postal.required
        "
        :numeric="
          $v.persona.codigo_postal.$dirty && !$v.persona.codigo_postal.numeric
        "
        inputmode="numeric"
      ></pd-input>
    </div>
    <div class="d-flex right">
      <button type="submit" class="btn btn-primary btn-align-right">
        Guardar mis datos
      </button>
    </div>
  </form>
</template>
<script>
import { required, numeric } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import { PersonasService } from "@/modules/personas/services/PersonasServices";
import Swal from "sweetalert2";
export default {
  name: "MisDatos",
  data() {
    return {
      persona: null,
    };
  },
  computed: {
    ...mapState(["user"]),
    cuilFormateado() {
      if (this.persona) {
        return PersonasService.formatCuil(this.persona.cuil);
      }
      return "";
    },
  },
  validations: {
    persona: {
      email: { required },
      telefono_fijo: { numeric },
      // celular: { required, numeric },
      domicilio: { required },
      localidad: { required },
      provincia: { required },
      codigo_postal: { required, numeric },
    },
  },
  mounted() {
    this.persona = { ...this.user.persona };
  },
  methods: {
    guardar() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      PersonasService.api
        .put(this.persona.id, this.persona)
        .then((response) => {
          if (response.success) {
            Swal.fire("Éxito", `<p>Se ha guardado con éxito</p>`, "success");
            this.$store
              .dispatch("attempt", localStorage.getItem("token"))
              .then(() => {
                this.$router.push({ name: "Inicio" });
              });
          } else {
            Swal.fire("Error", `<p>No se pudo guardar</p>`, "error");
          }
        })
        .catch((error) => {
          console.log(error);
          Swal.fire(
            "Error",
            `<p>No se pudo guardar</p><p>${error}</p>`,
            "error"
          );
        });
    },
  },
};
</script>